<template>
  <b-form-group
    label="Donanım Paketi"
    label-for="hardware"
  >
    <validation-provider
      #default="{ errors }"
      name="Donanım Paketi"
      rules="required"
    >
      <b-form-input
        id="hardware"
        v-model="dataItem.hardware"
        placeholder="Donanım Paketi"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'HardwareText',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/batchStock']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
