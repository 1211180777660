<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <brand-select />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <model-select />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <hardware-text />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <model-year />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <cc />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <fuel-select />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <gear-select />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <color-select />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <quantity />
          </b-col>
        </b-row>
        <b-alert
          variant="info"
          show
        >
          <div class="alert-body text-center">
            Toplu stok ekleme bölümünde, girdiğiniz araç bilgileri ve belirttiğiniz miktar doğrultusunda tahsis stok oluşturulur.<br>Kaydetmeden önce bilgilerinizi kontrol etmeyi unutmayın!
          </div>
        </b-alert>
      </b-card>
      <div v-if="dataItem.quantity > 0">
        <b-button
          variant="danger"
          @click="submitForm"
        >
          <FeatherIcon icon="SaveIcon" />
          {{ dataItem.quantity }} Tahsis Stok Ekle
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton, BAlert,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BrandSelect from '@/views/Sales/Stocks/AllocateStocks/BatchForm/BrandSelect.vue'
import ModelSelect from '@/views/Sales/Stocks/AllocateStocks/BatchForm/ModelSelect.vue'
import HardwareText from '@/views/Sales/Stocks/AllocateStocks/BatchForm/HardwareText.vue'
import ModelYear from '@/views/Sales/Stocks/AllocateStocks/BatchForm/ModelYear.vue'
import Cc from '@/views/Sales/Stocks/AllocateStocks/BatchForm/Cc.vue'
import FuelSelect from '@/views/Sales/Stocks/AllocateStocks/BatchForm/FuelSelect.vue'
import GearSelect from '@/views/Sales/Stocks/AllocateStocks/BatchForm/GearSelect.vue'
import ColorSelect from '@/views/Sales/Stocks/AllocateStocks/BatchForm/ColorSelect.vue'
import Quantity from '@/views/Sales/Stocks/AllocateStocks/BatchForm/Quantity.vue'

export default {
  name: 'BatchForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAlert,
    BrandSelect,
    ModelSelect,
    HardwareText,
    ModelYear,
    Cc,
    FuelSelect,
    GearSelect,
    ColorSelect,
    Quantity,
    ValidationObserver,
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/batchStock']
    },
  },
  methods: {
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('stock/allocateStockBatch', this.dataItem)
            .then(response => {
              if (response.status) {
                this.showToast({
                  title: 'İşlem Başarılı!',
                  icon: 'CheckIcon',
                  text: 'Tahsis stoklar oluşturuldu.',
                  variant: 'success',
                })
                this.$router.push('/stocks')
              } else {
                this.submitStatus = false
                this.sweetAlert({
                  icon: 'XIcon',
                  title: 'Hata oluştu.',
                  text: response.message,
                  html: '',
                  buttonText: 'Tekrar dene',
                })
              }
            })
        }
      })
    },
  },
}
</script>
