<template>
  <div>
    <b-tabs
      fill
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Toplu Stok Ekle</span>
        </template>
        <batch-form />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UploadIcon" />
          <span>Excel ile Aktar</span>
        </template>
        <excel-import />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import ExcelImport from '@/views/Sales/Stocks/AllocateStocks/ExcelImport.vue'
import BatchForm from '@/views/Sales/Stocks/AllocateStocks/BatchForm.vue'

export default {
  name: 'Index',
  components: {
    BTabs,
    BTab,
    ExcelImport,
    BatchForm,
  },
}
</script>
