<template>
  <b-form-group
    label="Marka"
    label-for="brand"
  >
    <validation-provider
      #default="{ errors }"
      name="Marka"
      rules="required"
    >
      <v-select
        id="brand"
        v-model="dataItem.id_com_brand"
        :options="brands"
        label="name"
        :reduce="brand => brand.id"
        placeholder="Marka"
        @input="getModels"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'BrandSelect',
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/batchStock']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
  },
  created() {
    this.getBrands()
    localize('tr')
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    getModels() {
      this.dataItem.id_com_model = null
      if (this.dataItem.id_com_brand) {
        this.$store.dispatch('models/modelsList', {
          where: {
            'com_model.id_com_brand': this.dataItem.id_com_brand,
          },
        })
      }
    },
  },
}
</script>
