<template>
  <b-form-group
    label="Yakıt Tipi"
    label-for="fuel"
  >
    <validation-provider
      #default="{ errors }"
      name="Yakıt Tipi"
      rules="required"
    >
      <v-select
        id="fuel"
        v-model="dataItem.id_com_fuel"
        :options="fuels"
        label="title"
        :reduce="item => item.id"
        placeholder="Yakıt Tipi"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'FuelSelect',
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stock/batchStock']
    },
    fuels() {
      return this.$store.getters['fuels/getFuels']
    },
  },
  created() {
    this.getFuels()
    localize('tr')
  },
  methods: {
    getFuels() {
      this.$store.dispatch('fuels/fuelsList')
    },
  },
}
</script>
